
<template>
  <div>
    <el-dialog
      title="新增文章"
      :visible.sync="visible"
      width="50%"
      :z-index="99"
      :close-on-click-modal="true"
      :show-close="true"
    >
      <a-row :gutter="[16, 16]">
        <a-col :span="24">
          <a-input placeholder="请输入标题..." v-model="titles" :maxLength="50"/>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="24">
          <quillEditor
            @onChange="quillHtml"
            v-if="fileType"
            ref="quillEditor"
          ></quillEditor>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" style="padding-left: 10px">
        <!-- 图片上传 -->
        <imageUpload ref="imageUpload"></imageUpload>
      </a-row>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          style="background-color: #556bff"
          :loading="saveType"
          @click="confirm"
          >保 存</el-button
        >
        <el-button @click="mapCancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
 <script>
import quillEditor from "../../../Acomponents/quillEditor/wangeditor.vue";
import imageUpload from "../../../Acomponents/ax-image-modal/src/image-upload.vue";
import api from "../articleList/api";
// import api from "../announcementList/api";
export default {
  components: { quillEditor, imageUpload },
  data() {
    return {
      api,
      visible: false,
      quillHtmls: "",
      fileType: true,
      saveType: false,
      titles: "",
    };
  },

  methods: {
    openDialog() {
      this.visible = true;
    },
    mapCancel() {
      this.visible = false;
      this.quillHtmls = "";
      this.titles = "";
      this.$refs.imageUpload.removeFileList();
      this.$refs.quillEditor.refresh();
    },
    async confirm() {
      if (this.quillHtmls === "" || this.titles === "") {
        this.$message.error("请填写完整信息");
        return;
      }

      this.saveType = true;
      try {
        let fileIds = [];
        this.$refs.imageUpload.getFile()[0]? fileIds = [this.$refs.imageUpload.getFile()[0].response.data.id]:fileIds =[]
        let obj = {
          title: this.titles,
          content: this.quillHtmls,
          fileIds,
        };
        const res = await api.addNotice(obj);
        if (res.status == 200) {
          this.$message.success("操作成功");
          this.mapCancel();
          this.saveType = false;
          this.$emit("refresh");
        } else {
          this.$message.error("操作失败");
          this.saveType = false;
        }
        this.saveType = false;
      } catch (error) {
        console.error(error);
        this.saveType = false;
      }
    },

    // 富文本编辑器获取内容
    quillHtml(e) {
      this.quillHtmls = e;
    },
  },

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

/deep/.ant-input {
  height: 0.2rem;
  border: none;
  color: #000;
  border-bottom: 1px solid#c9ced1;
  border-radius: 0;
}
/deep/.ant-input::placeholder {
  color: #000;
  font-size: 0.07rem;
  font-weight: 700;
}
</style>